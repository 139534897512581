@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; 
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600; 
  font-style: normal;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.logo {
  animation: fadeIn 2s;
}




